// Google Analytics custom events
// Including fallback handlers if the analytics library isn't loaded at the time the event happens

( function( $, window, document, undefined ) {

	const isAnalyticsLibraryLoaded = () => {
		return window.gtag && 'undefined' !== typeof window.gtag;
	};

	/**
	 * A class wrapper to collect any Google Analytics events fired 
	 * when the analytics library isn't loaded yet.
	 */
	const GA_Event_Store = class GA_Event_Store {
		constructor() {
			const _ = this;

			_.LOCAL_STORAGE_KEY = 'gaEvents';

			// Collect any previously stored events from local storage (IE 9+)
			_.storedEvents = [];

			if ( localStorage.getItem( _.LOCAL_STORAGE_KEY ) ) {
				_.storedEvents = JSON.parse( localStorage.getItem( _.LOCAL_STORAGE_KEY ) );
			}

			if ( _.storedEvents.length > 0 ) {

				if ( isAnalyticsLibraryLoaded() ) {
					_._sendOnInit();
				} else {
					// Poll once a second to see if library is loaded
					_._intervalID = setInterval( () => {
						if ( isAnalyticsLibraryLoaded() ) {
							_._sendOnInit();

							// Stop polling
							clearInterval( _._intervalID );
							_._intervalID = null;
						}
					}, 1000 );
				}

			}
		}
	
		/**
		 * Send any previously stored events when initialised.
		 * @return self
		 */
		_sendOnInit() {
			const _ = this;

			if ( _.storedEvents.length > 0 ) {
				_.storedEvents.forEach( (event) => {
					gtag( 'event', event.event_action, event );
				} );

				// Reset now that all events have been sent
				_.clear();
			}

			return _;
		}

		/**
		 * Store an event.
		 * @param  {Object} event The event properties specified as a fieldsObject
		 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events#examples
		 * @return self
		 */
		store( event ) {
			const _ = this;

			_.storedEvents.push( event );
			localStorage.setItem( _.LOCAL_STORAGE_KEY, JSON.stringify( _.storedEvents ) );

			return _;
		}

		/**
		 * Clear all stored events
		 * @return self
		 */
		clear() {
			const _ = this;

			_.storedEvents = [];
			localStorage.removeItem( _.LOCAL_STORAGE_KEY );

			return _;
		}

		/**
		 * Get any currently stored events.
		 * @return {Array} 
		 */
		get() {
			const _ = this;

			return _.storedEvents;
		}
	};

	let gaEventStore = new GA_Event_Store();

	// const GA_Event = class GA_Event {
	// 	constructor() {
	// 		// code
	// 	}
	// };


	// Expose API
	window.isAnalyticsLibraryLoaded = isAnalyticsLibraryLoaded;
	// window.GA_Event = GA_Event;
	window.gaEventStore = gaEventStore;
	
} )( jQuery, window, document );
