// Initialise carousel components

( function( $, window, document, undefined ) {

	let $carousel = $('.js-carousel'),
			$controls = $('[data-slick-target]'),
			options = {
				autoplay: true,
				autoplaySpeed: 3000,
				pauseOnHover: true,
				dots: false,
				arrows: false,
				infinite: true,
				adaptiveHeight: false,
				draggable: true,
				centerMode: true,
				centerPadding: '18%',
				slidesToShow: 1,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							centerPadding: '15%'
						}
					},
					{
						breakpoint: 800,
						settings: {
							centerPadding: '10%'
						}
					}
				]
			};

	if ( $carousel.length ) {
		$carousel.slick( options );

		if ( $controls.length ) {

			$carousel.on( 'beforeChange', ( event, slick, currentSlide, nextSlide ) => {
				let $numberControl = $(`[data-slick-action="slickGoTo(${ nextSlide })"]`);

				if ( $numberControl.length ) {
					$('.carousel__control.is-active').removeClass('is-active');
					$numberControl.addClass('is-active');
				}
			} );

		}
	}

	if ( $controls.length ) {
		$controls.click( ( event ) => {
			let $target = $(event.target);

			if ( !$target.is( '[data-slick-target]' ) ) {
				$target = $target.closest( '[data-slick-target]' );
			}

			let $slickTarget = $( $target.attr('data-slick-target') ),
					slickAction = $target.attr('data-slick-action'),
					slickActionArgs = null;

			if ( $slickTarget.length && slickAction ) {
				let regex = /(slickGoTo)\(\s*(\d+)\s*\)/,
						matches = regex.exec( slickAction );

				if ( matches ) {
					slickAction = matches[1];
					slickActionArgs = matches[2];
				}

				$slickTarget.slick( slickAction, slickActionArgs );
			}

			event.preventDefault();
			return false;
		} );
	}

} )( jQuery, window, document );
