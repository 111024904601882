// Contact Form Handler

( function( $, window, document, undefined ) {
  
  let $contactForm = $('#enquiry-form');

  if ( $contactForm.length ) {
    let _contactFormController = new ContactFormController( $contactForm );

    // Listen for bubbled events from button
    // Submit events fire default HTML5 validation as well
    $contactForm.find('#enquiry-form-submit').on( 'click', (event) => {

      if ( !_contactFormController.validate() ) {

        // Scroll to and focus first error
        let errorFound = false;
        $.each( _contactFormController.fields, ( index, field ) => {
          if ( field.hasError && !errorFound ) {
            // Force offset so that whole form element is showing
            window.SmoothScroll.setTempOffset(-100).toElement( field.$field.parent() ).restoreOffset();
            field.$field.focus();

            errorFound = true;
          }
        } );

        event.preventDefault();
        return false;

      }

    } );

    $contactForm.on( 'submit', (event) => {
      $contactForm.find('#enquiry-form-submit').html( 'Sending&hellip;' );

      let formSubmitted = false,
          eventData = {
            event_category: 'Enquiry Form',
            event_action: 'submit',
            event_label: 'AI Campaign'
          },
          eventCallback = function eventCallback() {
            if ( !formSubmitted ) {
              formSubmitted = true;
              $contactForm.get(0).submit();
            }
          };

      if ( isAnalyticsLibraryLoaded() ) {
        eventData.event_callback = eventCallback;
        gtag( 'event', eventData.event_action, eventData );

        // Fallback if no response after 1 second
        setTimeout( eventCallback, 1000);
      } else {
        gaEventStore.store( eventData );
        eventCallback();
      }

      event.preventDefault();

    } );
  }

  // Listen for change events and hide field errors
  $contactForm.find('input, textarea, select').not('[type="checkbox"]').not('[type="radio"]').on( 'change', (event) => {
    let $this = $(event.target),
        formField = new FormField( $this );

    $this.trigger( 'removeError' );
  } );

} )( jQuery, window, document );
