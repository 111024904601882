// Dialog Controller
// Handles opening and closing dialog windows

( function( $, window, document, undefined ) {
	
	class DialogController {
		constructor( selector ) {
			let _ = this;

			_.selector = selector;
			_.$dialog = $(selector);
			_._openClass = 'is-open';

			_._bindEvents();
		}

		_bindEvents() {
			let _ = this;

			if ( _.$dialog.find( '[data-dialog-close]' ).length ) {
				// Remove any previous events on the close button to prevent duplicates
				_.$dialog.find( '[data-dialog-close]' ).off( 'click.DialogController' );
				_.$dialog.find( '[data-dialog-close]' ).on( 'click.DialogController', (event) => {
					_.close();
				} );
			}

			$(document).on( 'keydown', (event) => {
				let escKeyCode = 27;

				if ( escKeyCode === event.keyCode && _.isOpen() ) {
					_.close();
				}
			} );

			return _;
		}
	
		open() {
			let _ = this;

			if ( _.$dialog.length ) {
				_.$dialog.addClass( _._openClass );
				ViewportScroll.disable();

				if ( window.matchWidth ) {
					window.matchWidth();
				}
			}

			return _;
		}

		close() {
			let _ = this;

			if ( _.$dialog.length ) {
				_.$dialog.removeClass( _._openClass );
				ViewportScroll.enable();
			}

			return _;
		}

		toggle() {
			let _ = this;

			if ( _.isOpen() ) {
				_.close();
			} else {
				_.open();
			}

			return _;
		}

		isOpen() {
			let _ = this;

			return _.$dialog.length && _.$dialog.hasClass( _._openClass );
		}
	}


	// Expose API
	window.DialogController = DialogController;

} )( jQuery, window, document );
