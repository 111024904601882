// Main initialisation script

( function( $, window, document, undefined ) {
	
	// Init dialog open buttons
	( function() {
		
		let $dialogButtons = $('[data-dialog]');

		if ( $dialogButtons.length ) {

			$dialogButtons.each( ( index, button ) => {
				let $button = $(button),
						$dialog = $( $button.data('dialog') ),
						dialog;

				if ( $dialog.length ) {
					dialog = new DialogController( $dialog );
					$button.get(0).dialog = dialog;

					$button.click( (event) => {
						$button.get(0).dialog.open();
					} );
				}
			} );

		}

	} )();

	( function() {
		
		let setVh = function() {
			let $setVh = $('[data-vh]');
		
			if ( windowWidth() < 650 ) {
				$setVh.each( ( index, element ) => {
					let $element = $(element),
							height = $(window).height() * $element.data('vh') / 100,
							offset = !$element.hasClass('dialog') ? $('.header').outerHeight() : 0;

					$element.css( 'height', height - offset );
				} );
			} else {
				$setVh.css( 'height', '' );
			}
		};

		setVh();

		$(window).resize( debounce( (event) => {
			setVh();
		}, 150 ) );

	} )();

	// Transition in hero overlays when in view
	( function() {
		
		let $heroOverlays = $('.js-hero-overlay'),
				overlaysOnScreen = 0;

		$heroOverlays.each( (index, overlay) => {
			let $overlay = $(overlay);

			if ( bottomIsInViewport( $overlay ) ) {
				$overlay.css( 'transition-delay', `${ 0.3 * overlaysOnScreen }s` );
				$overlay.addClass( 'is-in-view' );
				overlaysOnScreen++;
			}
		} ); 

		$(window).on( 'scroll', throttle( (event) => {
			$heroOverlays.each( (index, overlay) => {
				let $overlay = $(overlay);

				if ( bottomIsInViewport( $overlay ) ) {
					$overlay.addClass( 'is-in-view' );
				}
			} ); 
		}, 150 ) );

	} )();

	// Link up anchor links to smooth scroll
	( function() {
		
		// Select all links with hashes
		$('a[href*="#"]')
		  // Remove links that don't actually link to anything
		  .not('[href="#"]')
		  .not('[href="#0"]')
		  .click(function(event) {
		    // On-page links
		    if (
		      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
		      location.hostname == this.hostname
		    ) {
		      let $target = $(this.hash);
		      $target = $target.length ? $target : $('[name=' + this.hash.slice(1) + ']');

		      if ($target.length) {
		        window.SmoothScroll.toElement( $target );
		      }
		    }
		  });

	} )();

	// Match widths of targeted items
	( function() {
		
		let matchWidth = function() {
			let $elements = $('[data-match-width]'),
					value = 0;

			if ( windowWidth() <= 870 ) {

				$elements.css( 'width', '' );

				$elements.each( (index, element) => {
					let $element = $(element);

					value = Math.max( value, $element.outerWidth() );
				} );

				$elements.css( 'width', value );

			} else {
				$elements.css( 'width', '' );
			}
		};

		matchWidth();

		$(window).on( 'resize', debounce( function() {
			matchWidth();
		}, 150 ) );

		window.matchWidth = matchWidth;

	} )();


	// Track brochure downloads
	( function() {
	
		$('.js-brochure-download').click( function(event) {

			let $brochure = $(this),
					redirectFired = false,
          eventData = {
            event_category: 'Brochure Download',
            event_action: 'click',
            event_label: 'AI Campaign'
          },
          eventCallback = function eventCallback() {
            if ( !redirectFired ) {
              redirectFired = true;
              // window.location = $brochure.attr('href');
            }
          };

      if ( isAnalyticsLibraryLoaded() ) {
        eventData.event_callback = eventCallback;
        gtag( 'event', eventData.event_action, eventData );

        // Fallback if no response after 1 second
        setTimeout( eventCallback, 1000);
      } else {
        gaEventStore.store( eventData );
        eventCallback();
      }

      // event.preventDefault();

		} );

	} )();

} )( jQuery, window, document );
