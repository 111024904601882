// Initialise gallery carousel components

( function( $, window, document, undefined ) {

	let $carousel = $('.js-gallery-carousel'),
			$controls = $('[data-slick-target]'),
			options = {
				autoplay: true,
				autoplaySpeed: 2000,
				pauseOnHover: false,
				dots: false,
				arrows: false,
				infinite: true,
				adaptiveHeight: false,
				draggable: true
			};

	if ( $carousel.length ) {
		$carousel.slick( options );

		if ( $controls.length ) {

			$carousel.on( 'beforeChange', ( event, slick, currentSlide, nextSlide ) => {
				let $numberControl = $(`[data-slick-action="slickGoTo(${ nextSlide })"]`);

				if ( $numberControl.length ) {
					$('.carousel__control.is-active').removeClass('is-active');
					$numberControl.addClass('is-active');
				}
			} );

		}
	}

} )( jQuery, window, document );
