// Utility JavaScript functions
// Comment-out switches are wrapped around each block
// Use them to turn on/off specific utilities

/**
 * Force a repaint of a specific DOM Node
 * @param  {DOMElement} node A native DOM element to repaint.
 *                           Not jQuery, use $element.get(0) to convert.
 */
// let repaint = ( node ) => {
//  node.style.display = 'none';
//  node.offsetHeight; // jshint ignore:line
//  node.style.display = '';
// };

/**
 * Get a random integer between min and max.
 * @param  {Number} min The minimum integer to return.
 * @param  {Number} max The maximum integer to return.
 * @return {Number}
 */
let randBetween = ( min, max ) => Math.floor(Math.random() * (max - min + 1) ) + min;

/**
 * Get the true window width, without scrollbars.
 * @return integer The window width in px.
 */
//*
function windowWidth() {
  // Temporarily disable scrollbar
  jQuery('body').css( {
    overflow: 'hidden',
  } );

  // Get window width
  var w = jQuery(window).width();

  // Remove styles
  jQuery('body').css( {
    overflow: '',
  } );

  return w;
}
//*/

/**
 * Check if an element has been scrolled past, 
 * i.e. it is on screen or above the viewport.
 * @param  {jQuery}  $element The element to check.
 * @return {Boolean}          
 */
function hasBeenScrolledPast( $element ) {
  var elementTop = $element.offset().top,
      screenTop = jQuery(window).scrollTop(),
      screenBottom = screenTop + jQuery(window).outerHeight();

  return screenBottom >= elementTop;
}

/**
 * Check if the bottom of the element is inside the viewport or above.
 * @param  {jQuery}  $element The element to check.
 * @return {Boolean}          
 */
function bottomIsInViewport( $element ) {
  var elementTop = $element.offset().top,
      elementBottom = elementTop + $element.outerHeight(false),
      screenTop = jQuery(window).scrollTop(),
      screenBottom = screenTop + jQuery(window).outerHeight();

  return screenBottom >= elementBottom;
}

/**
 * Returns a function that will be called at most every N milliseconds.
 */
//*
function throttle(fn, threshold, scope) {
  threshold = threshold || 250;
  var last,
      deferTimer;
  return function () {
    var context = scope || this;

    var now = +new Date(),
        args = arguments;
    if (last && now < last + threshold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}
//*/

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
//*
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
//*/

// cookie function from https://github.com/filamentgroup/cookie/
function cookie( name, value, days ){
  var expires;
  // if value is undefined, get the cookie value
  if( value === undefined ){
    var cookiestring = "; " + window.document.cookie;
    var cookies = cookiestring.split( "; " + name + "=" );
    if ( cookies.length == 2 ){
      return cookies.pop().split( ";" ).shift();
    }
    return null;
  }
  else {
    // if value is a false boolean, we'll treat that as a delete
    if( value === false ){
      days = -1;
    }
    if ( days ) {
      var date = new Date();
      date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) );
      expires = "; expires="+date.toGMTString();
    }
    else {
      expires = "";
    }
    window.document.cookie = name + "=" + value + expires + "; path=/";
  }
}

/**
 * Create an XHR request wrapped in a Promise interface.
 * @param {Object} options An object containing at least method and url keys.
 *                         Optionally data and headers keys can be passed as well.
 *                         -
 *                         {String} method    The HTTP method to make the request with.
 *                         {String} url       The URL to send the request to.
 *                         {Object} [data]    An object containing parameters for the request. 
 *                         {Object} [headers] An object containing HTTP headers. Each object
 *                                            key should be the header name wrapped as a string.
 * @return {Promise}       A Promise object which is resolved when the request
 *                         returns and rejected when it errors.
 */
/*
let makeRequest = ( options ) => {
  return new Promise( ( resolve, reject ) => {

    let xhr = new XMLHttpRequest();

    xhr.open( options.method, options.url );

    if ( options.headers ) {
      Object.keys( options.headers ).forEach( (key) => xhr.setRequestHeader( key, options.headers[key] ) );
    }

    xhr.onload = () => {
      resolve( xhr.response );
    };

    xhr.onerror = () => {
      reject( xhr );
    };

    // url encode form data for sending as post data
    let encodedData = options.data ? Object.keys( options.data ).map( ( key ) => {
      return encodeURIComponent( key ) + '=' + encodeURIComponent( options.data[key] );
    } ).join('&') : null;

    xhr.send( encodedData );

  } );
};
//*/
