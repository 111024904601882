// Contact Form Validation

( function( $, window, document, undefined ) {

	/**
   * Represents a single form field.
   * Handles showing errors and validating common field types.
   */
  let FormField = class FormField {
    constructor( $field ) {
      let _ = this;

      _.$field = $field;
      _.hasError = false;

      $field.on( 'removeError', () => _.removeError() );
    }

    /**
     * Alias val() function from jQuery.
     */
    val() {
      let _ = this;

      return _.$field.val();
    }
  
    /**
     * Show an error attached to the field.
     * @param {String} message The error message to show.
     * @return {self}
     */
    addError( message ) {
      let _ = this,
          fieldMessage = new FormFieldMessage( _.$field );

      _.$field.addClass('has-error');
      
      fieldMessage.setStatus( 'error' ).setMessage( message );
      _.hasError = true;

      return _;
    }

    /**
     * Remove an error being shown on the field.
     * @return {self}
     */
    removeError() {
      let _ = this,
          fieldMessage = new FormFieldMessage( _.$field );

      _.$field.removeClass('has-error');

      fieldMessage.hide();
      _.hasError = false;

      return _;
    }

    /**
     * Check if the field is required and if it is,
     * whether it has a non empty value.
     * @return {Boolean}
     */
    isRequiredAndValid() {
      let _ = this;

      return _.$field.is('[required]') && _.$field.val() !== "";
    }

    /**
     * Check if the field has a minimum length
     * determined by it's HTML attributes.
     * @return {Boolean}
     */
    hasMinLength() {
      let _ = this,
          // Force string type
          value = _.$field.val() + "",
          minLength = _.$field.attr( 'minlength' );

      return value.length >= minLength;
    }

    /**
     * Check if the field is a standard email format.
     * @return {Boolean}
     */
    isEmail() {
      let _ = this,
          emailRegex = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i;

      return emailRegex.test( _.$field.val() );
    }

    /**
     * Check if the field is a standard phone number format.
     * Because some people may wish to write values such as
     *   "Day 0121... Night 07999..."
     * this check only requires at least 9 numbers in the field.
     * @return {Boolean}
     */
    isPhone() {
      let _ = this,
          replaceRegex = /[^0-9+]/g,
          phoneValue = _.$field.val().replace( replaceRegex, '' );

      return phoneValue.length >= 9;
    }
  };

  /**
   * A single message element attached to a form field.
   * The message can represent information or an error in the value entered.
   */
  let FormFieldMessage = class FormFieldMessage {
    constructor( $field ) {
      let _ = this;

      _.$field = $field;
      
      _._elementSelector = '.js-form-message';
      _._animationSpeed = 250;

      _.$message = _.getElement();
    }

    /**
     * Get a jQuery element representing a 
     * message attached to the form field.
     * If there is not a message element, one will be created.
     * @return {jQuery} The message element, found or creeated.
     */
    getElement() {
      let _ = this,
          $message = _.$field.siblings( _._elementSelector );

      if ( !$message.length ) {
        $message = $( `<p class="form__message js-form-message" data-field="#${ _.$field.attr('id') }" style="display:none;"></p>` );
        // Insert into DOM
        _.$field.after( $message );
      }

      return $message;
    }

    /**
     * Set the status of the message.
     * @param {String} status The status to set. Can be anything, however 
     *                        some choices are: notice, info, error.
     * @return {self}
     */
    setStatus( status ) {
      let _ = this;

      _.$message.addClass( `form__message--${status} js-form-message--${status}` );

      return _;
    }

    /**
     * Set the message text.
     * @param {String} message The text to set.
     * @return {self}
     */
    setMessage( message ) {
      let _ = this;

      _.$message.html( message );

      if ( !_.$message.is(':visible') ) {
        _.show();
      } else {
        _.blink();
      }

      return _;
    }

    /**
     * Show the message.
     * @return {self} 
     */
    show() {
      let _ = this;

      _.$message.slideDown( _._animationSpeed );

      return _;
    }

    /**
     * Make the message blink once to attract attention.
     * @return {self} 
     */
    blink() {
      let _ = this;

      _.$message.hide().fadeIn( _._animationSpeed );

      return _;
    }

    /**
     * Hide the message.
     * @return {self} 
     */
    hide() {
      let _ = this;

      _.$message.slideUp( _._animationSpeed );

      return _;
    }
  };

  let ContactFormController = class ContactFormController {
    constructor( $form ) {
      let _ = this;

      _.$form = $form;

      _.fields = {
        fullname: new FormField( _.$form.find('#fullname') ),
        email: new FormField( _.$form.find('#email') ),
        phone: new FormField( _.$form.find('#phone') ),
        enquiry: new FormField( _.$form.find('#enquiry') )
      };
    }

    /**
     * Validate the form.
     * @return {Boolean} True if the form validated with no errors,
     *                   false otherwise.
     */
    validate() {
      let _ = this,
          fields = _.fields,
          validated = true;

      if ( !fields.fullname.isRequiredAndValid() ) {
        fields.fullname.addError( 'Enter your name' );
        validated = false;
      } else if( !fields.fullname.hasMinLength() ) {
        fields.fullname.addError( `Your name should be at least ${ fields.fullname.$field.attr('minlength') } characters` );
        validated = false;
      }

      if ( !fields.email.isRequiredAndValid() ) {
        fields.email.addError( 'Enter your email' );
        validated = false;
      } else if ( !fields.email.isEmail() ) {
        fields.email.addError( 'Enter a valid email' );
        validated = false;
      }

      if ( !fields.phone.isRequiredAndValid() ) {
        fields.phone.addError( 'Enter your phone number' );
        validated = false;
      } else if ( !fields.phone.isPhone() ) {
        fields.phone.addError( 'Enter a valid phone number' );
        validated = false;
      }

      if ( !fields.enquiry.isRequiredAndValid() ) {
        fields.enquiry.addError( 'Enter an enquiry' );
        validated = false;
      } else if( !fields.enquiry.hasMinLength() ) {
        fields.enquiry.addError( `Your enquiry should be at least ${ fields.enquiry.$field.attr('minlength') } characters` );
        validated = false;
      }


      return validated;
    }
  };


  // Expose API
  window.FormField = FormField;
  window.FormFieldMessage = FormFieldMessage;
  window.ContactFormController = ContactFormController;
	
} )( jQuery, window, document );
